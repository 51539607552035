import { Badge, Box, Link, Popover, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { useWeddingWebsiteSlice } from 'app/GuestView/pages/WeddingWebsite/slice';
import { selectWebsiteDetail } from 'app/GuestView/pages/WeddingWebsite/slice/selectors';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { setSidebarVisible } from '../../../store/slice/common';
import {
  selectGroups,
  selectRooms,
  selectSidebarVisible,
  selectUser,
  selectWeddingInfo,
} from '../../../store/slice/common/selectors';
import appRoute from '../../../utils/appRoute';
import { useIsMobile } from 'utils/responsiveHelper';
import { isGuest, isLeader, isSalesLeadAny } from 'utils/userHelper';
import { IconAngleRight } from '../Icons/IconAngleRight';
import { IconSideBarClose } from '../Icons/IconSideBarClose';
import { ContactUs } from '../Icons/Menu/ContactUs';
import { FAQs } from '../Icons/Menu/FAQs';

import { GuestList } from '../Icons/Menu/GuestList';
import { ManageRoomExtras } from '../Icons/Menu/ManageRoomExtras';
import { Rooms } from '../Icons/Menu/Rooms';
import { RSVP } from '../Icons/Menu/RSVP';
import { WeddingReservation } from '../Icons/Menu/WeddingReservation';
import { WeddingWebsite } from '../Icons/Menu/WeddingWebsite';
import UserStringAvatar from '../Misc/UserStringAvatar';
import MenuCategoryItem from './MenuCategoryItem';
import MenuItem from './MenuItem';

import { UserInfo } from 'types/UserInfo';

interface Props {
  className?: string;
  children?: any;
}

interface MenuProps {
  goToPage: Function;
}

type AppRoute = typeof appRoute;

type RouteMenuItemParams = {
  routeName: keyof AppRoute;
  routeParams?: any[]; //this started exploding on me for no reason until I added the ? operator...
  icon: ReactElement;
  title: string;
  goToPage: Function;
  visible?: boolean;
  altRouteNames?: Array<keyof AppRoute>;
};
const RouteMenuItem = (params: RouteMenuItemParams) => {
  const url = appRoute[params.routeName](...params.routeParams);
  const routeList = [
    appRoute[params.routeName](),
    ...(params.altRouteNames || []).map(rn => appRoute[rn]()),
  ];
  const className = useRouteMatch(routeList) ? 'active' : undefined;

  return (
    (params.visible ?? true) && (
      <MenuItem
        title={params.title}
        icon={params.icon}
        onClick={() => {
          params.goToPage(url);
        }}
        className={className}
      />
    )
  );
};

type SalesViewRouteMenuItemParams = {
  isSideBar: boolean;
  routeName: keyof AppRoute;
  routeParams: any[];
  title: string;
  goToPage: Function;
  visible?: boolean;
  altRouteNames?: Array<keyof AppRoute>;
  icon?: boolean;
};
const SalesViewRouteMenuItem = (params: SalesViewRouteMenuItemParams) => {
  const url = appRoute[params.routeName](...params.routeParams);
  const routeList = [
    appRoute[params.routeName](),
    ...(params.altRouteNames || []).map(rn => appRoute[rn]()),
  ];
  const className = useRouteMatch(routeList) ? 'active' : undefined;

  if (params.isSideBar) {
    return (
      (params.visible ?? true) && (
        <MenuCategoryItem
          title={params.title}
          onClick={() => {
            params.goToPage(url);
          }}
          className={className}
        />
      )
    );
  } else {
    return (
      (params.visible ?? true) && (
        <Button
          data-testid={`menu-item-button-${params.title
            .replace(/\s+/g, '-')
            .toLowerCase()}`}
          variant="text"
          onClick={() => {
            params.goToPage(url);
          }}
          endIcon={params.icon && <ExpandMoreIcon />}
          size="medium"
        >
          {params.title}
        </Button>
      )
    );
  }
};

export const SideBarMainMenu = ({ goToPage }: MenuProps) => {
  const user = useSelector(selectUser);
  const rooms = useSelector(selectRooms);

  const hasRooms = rooms && rooms.length > 0;
  // try to save the selected item
  const { roomId = 'first' } = useParams<{ roomId?: string }>();

  const nodeEnv = useSelector((state: any) => state?.common?.nodeEnv);

  return (
    <>
      {(isLeader(user) || isSalesLeadAny(user)) && (
        <WeddingManagementLinks
          nodeEnv={nodeEnv}
          goToPage={goToPage}
          isSideBar={true}
          user={user}
        />
      )}

      <>
        <AccordionStyled
          key={Math.random()}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={true}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              My Trips
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              visible
              goToPage={goToPage}
              routeName={'roomsPage'}
              routeParams={[roomId]}
              title="My Rooms"
              icon={<Rooms />}
            />
            <RouteMenuItem
              visible={hasRooms}
              goToPage={goToPage}
              routeName={'roomExtrasPage'}
              routeParams={[roomId]}
              title="My Transfers"
              icon={<ManageRoomExtras />}
            />
          </AccordionDetailsStyled>
        </AccordionStyled>

        {(isGuest(user) || isLeader(user)) && (
          <AccordionStyled
            key={Math.random()}
            variant={'outlined'}
            disableGutters={true}
            square={true}
            defaultExpanded={true}
          >
            <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
              <Typography
                fontFamily={'"Nunito", sans-serif'}
                fontSize={18}
                fontWeight={400}
              >
                Support
              </Typography>
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <RouteMenuItem
                goToPage={goToPage}
                routeName={'contactUsPage'}
                routeParams={['first']}
                title="Contact Us"
                icon={<ContactUs />}
              />
            </AccordionDetailsStyled>
          </AccordionStyled>
        )}
        {/* New FAQs Accordion */}
        <AccordionStyled
          key={Math.random()}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={true}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              FAQs
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <MenuItem
              title="Help Center"
              icon={<FAQs />}
              onClick={() =>
                window.open(
                  'https://help.destify.com',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      </>
    </>
  );
};

export const TopBarMainMenu = ({ goToPage }: MenuProps) => {
  const user = useSelector(selectUser);
  const rooms = useSelector(selectRooms);

  const hasRooms = rooms && rooms.length > 0;
  // try to save the selected item
  const { roomId = 'first' } = useParams<{ roomId?: string }>();

  const nodeEnv = useSelector((state: any) => state?.common?.nodeEnv);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick1 = event => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = event => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  return (
    <>
      <SalesViewRouteMenuItem
        goToPage={goToPage}
        routeName={'homePage'}
        routeParams={['']}
        title="Home"
        isSideBar={false}
      />

      {user && (
        <>
          {(isLeader(user) || isSalesLeadAny(user)) && (
            <WeddingManagementLinks
              nodeEnv={nodeEnv}
              goToPage={goToPage}
              isSideBar={false}
              user={user}
            />
          )}

          <Button
            size="medium"
            endIcon={<ExpandMoreIcon />}
            variant="text"
            onClick={handleClick1}
            data-testid={'nav-tabs-trip-management'}
          >
            My Trips
          </Button>
          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <RouteMenuItem
              visible
              goToPage={goToPage}
              routeName={'roomsPage'}
              routeParams={[roomId]}
              title="My Rooms"
              icon={<Rooms />}
            />
            <RouteMenuItem
              visible={hasRooms}
              goToPage={goToPage}
              routeName={'roomExtrasPage'}
              routeParams={[roomId]}
              title="My Transfers"
              icon={<ManageRoomExtras />}
            />
          </Popover>
        </>
      )}

      {(isGuest(user) || isLeader(user)) && (
        <>
          <Button
            onClick={handleClick2}
            data-testid={'nav-tabs-support-center'}
            size="medium"
            endIcon={<ExpandMoreIcon />}
            variant="text"
          >
            Support
          </Button>

          <Popover
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'contactUsPage'}
              routeParams={['first']}
              title="Contact Us"
              icon={<ContactUs />}
            />
          </Popover>
          {/* New FAQs Button and Popover */}
          <Button
            onClick={handleClick3}
            data-testid={'nav-tabs-faqs'}
            size="medium"
            endIcon={<ExpandMoreIcon />}
            variant="text"
          >
            FAQs
          </Button>
          <Popover
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              title="Help Center"
              icon={<FAQs />}
              onClick={() =>
                window.open(
                  'https://help.destify.com',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            />
          </Popover>
        </>
      )}

      {!user && (
        <Link href="https://destify.com/contact-us/" target="_blank">
          <Button
            variant="text"
            data-testid="menu-item-button-contact-us"
            size="medium"
          >
            Contact Us
          </Button>
        </Link>
        // <SalesViewRouteMenuItem
        //   goToPage={goToPage}
        //   routeName={'support'}
        //   routeParams={['']}
        //   title="Contact Us"
        //   isSideBar={false}
        //   icon={false}
        // />
      )}
    </>
  );
};

const AccordionStyled = styled(Accordion)`
  border: none;

  &.MuiAccordion-root:before {
    content: none;
  }
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  margin: 0;
  padding: 14px 20px;
  border-bottom: 1px solid #f2f2f2;

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

function WeddingManagementLinks({
  isSideBar,
  nodeEnv,
  goToPage,
  user,
}: {
  isSideBar: boolean;
  nodeEnv: 'production' | 'development';
  goToPage: Function;
  user?: UserInfo;
}) {
  const weddingInfo = useSelector(selectWeddingInfo);
  const [showWebsiteLink, setShowWebsiteLink] = useState(false);
  const dispatch = useDispatch();
  const weddingWebsiteActions = useWeddingWebsiteSlice()?.actions;
  const websiteDetail = useSelector(selectWebsiteDetail);
  const groupInfo = useSelector(selectGroups);

  useEffect(() => {
    if (user && isLeader(user) && !websiteDetail) {
      dispatch(weddingWebsiteActions.loadWebsiteDetail());
    }

    /**
     * We should only show the wedding website link if the user has a website
     * with one of the new 'border' or 'no-border' themes, or if they have
     * not registered a website yet.
     */
    let userHasRegisteredWebsite = true;
    if (!!websiteDetail) {
      userHasRegisteredWebsite = !!websiteDetail?.websiteUrl;
    }

    const websiteHasEditableTheme = websiteDetail?.theme
      ?.toLowerCase()
      ?.includes('border');

    if (websiteHasEditableTheme || !userHasRegisteredWebsite) {
      setShowWebsiteLink(true);
    } else {
      setShowWebsiteLink(false);
    }
  }, [
    weddingInfo,
    websiteDetail,
    groupInfo,
    dispatch,
    weddingWebsiteActions,
    user,
  ]);

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleClick1 = event => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);

  if (isSideBar) {
    return (
      <AccordionStyled
        key={Math.random()}
        variant={'outlined'}
        disableGutters={true}
        square={true}
        defaultExpanded={true}
      >
        <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
          <Typography
            fontFamily={'"Nunito", sans-serif'}
            fontSize={16}
            fontWeight={400}
          >
            My Wedding
          </Typography>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          {nodeEnv !== 'production' && ( // only show these on dev
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingReservationPage'}
              routeParams={['first']}
              title="Wedding Reservation"
              icon={<WeddingReservation />}
            />
          )}
          {showWebsiteLink && (
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['first']}
              title="Wedding Website"
              icon={<WeddingWebsite />}
            />
          )}
          <RouteMenuItem
            goToPage={goToPage}
            routeName={'rsvpsPage'}
            routeParams={['first']}
            title="RSVPs"
            icon={<RSVP />}
          />
          <RouteMenuItem
            goToPage={goToPage}
            routeName={'guestListPage'}
            routeParams={['first']}
            title="Guest List"
            icon={<GuestList />}
          />
        </AccordionDetailsStyled>
      </AccordionStyled>
    );
  } else {
    return (
      <>
        <Button
          onClick={handleClick1}
          data-testid={'nav-tabs-wedding-management'}
          size="medium"
          endIcon={<ExpandMoreIcon />}
          variant="text"
        >
          My Wedding
        </Button>
        <Popover
          open={open1}
          anchorEl={anchorEl1}
          onClose={handleClose1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {nodeEnv !== 'production' && ( // only show these on dev
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingReservationPage'}
              routeParams={['first']}
              title="Wedding Reservation"
              icon={<WeddingReservation />}
            />
          )}
          {showWebsiteLink && (
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['first']}
              title="Wedding Website"
              icon={<WeddingWebsite />}
            />
          )}
          <RouteMenuItem
            goToPage={goToPage}
            routeName={'rsvpsPage'}
            routeParams={['first']}
            title="RSVPs"
            icon={<RSVP />}
          />
          <RouteMenuItem
            goToPage={goToPage}
            routeName={'guestListPage'}
            routeParams={['first']}
            title="Guest List"
            icon={<GuestList />}
          />
        </Popover>
      </>
    );
  }
}

export const AppSideBarMenu = memo((props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const className = props.className;
  const user = useSelector(selectUser);

  const sidebarVisible = useSelector(selectSidebarVisible);
  const drawerVariant = 'temporary';

  const goToPage = (path: string) => {
    history.push(path);
    closeSideBar();
  };

  const isMobile = useIsMobile('md');

  const closeSideBar = () => {
    dispatch(setSidebarVisible(false));
  };

  // useEffect(() => {
  //   dispatch(setSidebarVisible(isMobile));
  // }, []);

  return (
    <>
      {isMobile && (
        <Drawer
          variant={drawerVariant}
          open={sidebarVisible}
          onClose={closeSideBar}
          className={className}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            width={360}
            minHeight={52}
            padding={'0 20px'}
            borderBottom={'1px solid #f2f2f2'}
          >
            <Stack
              flex={1}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              {user && (
                <Stack direction={'row'} alignItems={'center'}>
                  <StyledBadge
                    overlap={'circular'}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    variant={'dot'}
                  >
                    <UserStringAvatar user={user} isSideBar={true} />
                  </StyledBadge>
                  <Stack direction={'column'} pl={'14px'}>
                    <Typography
                      variant={'body1'}
                      fontWeight={400}
                      lineHeight={1}
                      mb={'5px'}
                    >
                      {user.firstName + ' ' + user.lastName}
                    </Typography>
                    <Typography variant={'body2'} lineHeight={1}>
                      {user.email}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              <Box
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                }}
                data-testid="app-side-bar-button-close"
                onClick={() => closeSideBar()}
              >
                <IconSideBarClose />
              </Box>
            </Stack>
          </Box>

          <SideBarMainMenu goToPage={goToPage} />
        </Drawer>
      )}
    </>
  );
});

export const AppTopBarMenu = () => {
  const history = useHistory();

  const goToPage = (path: string) => {
    history.push(path);
  };

  const isMobile = useIsMobile('md');

  return (
    <>
      {!isMobile && (
        <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
          <TopBarMainMenu goToPage={goToPage} />
        </Stack>
      )}
    </>
  );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 3.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
