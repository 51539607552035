import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
  Link,
  styled,
} from '@mui/material';
import Carousel from 'react-multi-carousel';
import { RationalWrapper } from 'app/components/CardMediaRational';
import CardRowSkeleton from 'app/components/Skeletons/CardRowSkeleton';
import appRoute from 'utils/appRoute';
import { RoomStatusChip } from 'app/components/RoomStatusChip';
import { RoomInfo } from 'types/RoomInfo';
import { responsive } from '../constants';

interface IReservation {
  traveler: string;
  hotelImage: string;
  hotelName: string;
  roomName: string;
  travelStartDate: string;
  travelEndDate: string;
  roomType: string;
  roomId: string;
  daysTillTravel: number;
  status: string;
}

const RoomCarousel = ({ roomList }: { roomList: IReservation[] }) => {
  return (
    <Carousel
      swipeable
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={false} // means to render carousel on server-side.
      infinite={false}
      autoPlay={false}
      autoPlaySpeed={1000}
      keyBoardControl
      containerClass="carousel-container"
      itemClass="carousel-item"
    >
      {roomList.map(r => (
        <ReservationCard
          item={{ ...r, title: 'Room Details' }}
          key={r.roomId}
        />
      ))}
    </Carousel>
  );
};

const ReservationCardDate = ({ items }) => {
  return (
    <ReservationCardDateWrapper>
      {items.map((item, i) => {
        return (
          <Stack key={i}>
            <Typography>{item.label}</Typography>
            <Typography fontWeight={'bold'}>{item.date}</Typography>
          </Stack>
        );
      })}
    </ReservationCardDateWrapper>
  );
};

const ReservationCardDescription = ({ title, items }) => {
  return (
    <ReservationCardDescriptionWrapper>
      <Typography variant="h5" fontWeight={'bold'}>
        {title}
      </Typography>
      {items.map(item => {
        return (
          <Stack
            key={item.value}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Typography>{item.label}</Typography>
            <Typography textOverflow={'ellipsis'} fontWeight={'bold'}>
              {item.value}
            </Typography>
          </Stack>
        );
      })}
    </ReservationCardDescriptionWrapper>
  );
};

const ReservationCard = ({
  item,
}: {
  item: IReservation & { title: string };
}) => {
  return (
    <LinkWrapper href={appRoute.roomsPage(item.roomId)}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          margin: '0 auto',
          cursor: 'pointer',
        }}
      >
        <RationalWrapper ratio={1.6}>
          <CardMedia
            component="img"
            image={item.hotelImage}
            alt={item.hotelName}
            sx={{ width: '100%' }}
          />
        </RationalWrapper>
        <CardContent sx={{ flexGrow: 1 }}>
          {/* title */}
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={2}
          >
            <Typography variant={'h3'} mb={1} fontWeight={'bold'}>
              {item.hotelName}
            </Typography>
            <Box
              sx={{
                gridArea: 'roomStatus',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                minWidth: '90px',
              }}
            >
              {item.status && <RoomStatusChip status={item.status} />}
            </Box>
          </Stack>
          {/* description */}
          <Box>
            <ReservationCardDescription
              title={'Room Details'}
              items={[
                { label: 'Room Name', value: item.roomName },
                {
                  label: 'Room Type',
                  value: item.roomType,
                },
              ]}
            />
            <ReservationCardDate
              items={[
                {
                  label: 'Check In',
                  date: item.travelStartDate,
                },
                {
                  label: 'Check Out',
                  date: item.travelEndDate,
                },
              ]}
            />
          </Box>
        </CardContent>
      </Card>
    </LinkWrapper>
  );
};

const Reservations = ({ items }: { items: RoomInfo[] }) => {
  const reservationsData = useMemo(
    () =>
      items.map(item => {
        const { room: r, hotel: h, travelers: t } = item;
        const [{ hotelImage, hotelName }] = h;
        const [{ firstName, middleName, lastName }] = t;
        const [
          {
            id,
            roomName,
            roomType,
            roomStatus,
            travelStartDate,
            travelEndDate,
            daysTillTravel,
          },
        ] = r;
        return {
          traveler: `${firstName} ${middleName} ${lastName}`,
          hotelImage,
          hotelName,
          roomName,
          travelStartDate,
          travelEndDate,
          roomType,
          roomId: id,
          daysTillTravel,
          status: roomStatus,
        };
      }),
    [items],
  );
  return (
    <>
      <Box px={2} mb={2}>
        <Typography variant={'h4'}>Your Reservations</Typography>
        <Divider />
      </Box>
      <Box>
        {reservationsData.length === 0 ? (
          <CardRowSkeleton timeoutHideMs={30_000}>
            <Box px={2}>
              You do not seem to have any trips associated with your
              account.
              <br />
              Please reach out to Guest Services at{' '}
              <a href="mailto:guestservices@destify.com">
                GuestServices@destify.com
              </a>{' '}
              for any questions
            </Box>
          </CardRowSkeleton>
        ) : (
          <RoomCarousel roomList={reservationsData} />
        )}
      </Box>
    </>
  );
};

export default Reservations;

const LinkWrapper = styled(Link)`
  text-decoration: none;
`;

const ReservationCardDateWrapper = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    '--spacing': '.5em',
    borderTop: '2px solid',
    borderTopColor: theme.palette.primary.grey,
    padding: 'var(--spacing) 0',
    justifyContent: 'space-between',
    '> *': {
      flex: 1,
      ':not(:first-child)': {
        borderLeft: '2px solid',
        paddingLeft: 'calc(1.75 * var(--spacing))',
        borderLeftColor: theme.palette.primary.grey,
      },
    },
  };
});

const ReservationCardDescriptionWrapper = styled(Box)(({ theme }) => {
  return {
    paddingBlock: '.5em',
    '& p': {
      whiteSpace: 'nowrap',
      lineHeight: '2em',
      '&:not(:first-child)': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginLeft: '.5em',
      },
    },
  };
});
