import * as React from 'react';
import { Box } from '@mui/material';
import { RoundedButton } from '../../../../../components/RoundedButton';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';

import appRoute from '../../../../../../utils/appRoute';
import { useHistory } from 'react-router-dom';
import { SignUpForRoomLayout } from '../../layout';
import { useQuery } from 'hooks';
import { ConfirmSignUpModal } from '../../modals/confirmSignUpModal';
import { selectSignUpForRoom } from '../../slice/selectors';
import { useSelector } from 'react-redux';
import { notify } from 'utils/misc';

const REACT_APP_GOOGLE_CLIENTID = process.env.REACT_APP_GOOGLE_CLIENTID;
const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

interface SignUpForRoomHomeProps { }

export function SignUpForRoomHome(props: SignUpForRoomHomeProps) {
    const history = useHistory();
    const query = useQuery();
    const { isSigningUp } = useSelector(selectSignUpForRoom);
    const [socialModalOpen, setSocialModalOpen] = React.useState(false);
    const [disabledProviders, setDisabledProviders] = React.useState<string[]>(
        [],
    );
    const [socialData, setSocialData] =
        React.useState<Record<string, string | undefined>>();

    const goToPage = (path: string) => {
        history.push({ pathname: path, search: query.toString() });
    };

    const resetSocialSignIn = () => {
        setSocialModalOpen(false);
        setSocialData({});
    };

    const responseFacebook = response => {
        console.log('responseFacebook', JSON.stringify(response));
        if (
            response &&
            'name' in response &&
            'email' in response &&
            'id' in response
        ) {
            const fullName = response.name.split(' ');

            setSocialData({
                firstName: fullName[0] || 'MISSING',
                lastName: fullName[1] || 'MISSING',
                email: response.email,
                provider: 'facebook',
                providerKey: response.id,
            });
            setSocialModalOpen(true);
        } else {
            console.warn(`Facebook login failure: ${JSON.stringify(response)}`);
            notify(
                'Error',
                <p>
                    An error was encountered trying to log you in, with Facebook.
                    <br />
                    Please, try again.
                </p>,
                'danger',
            );
        }
    };

    const handleSuccessResponseFromGoogle = response => {
        console.log('handleSuccessResponseFromGoogle', JSON.stringify(response));
        if (response?.googleId && response.profileObj?.email) {
            setSocialData({
                firstName: response.profileObj.givenName || 'MISSING',
                lastName: response.profileObj.familyName || 'MISSING',
                email: response.profileObj.email,
                provider: 'google',
                providerKey: response.googleId,
            });
            setSocialModalOpen(true);
        } else {
            console.warn(
                `Google login failure (missing info): ${JSON.stringify(response)}`,
            );
            notify(
                'Error',
                <p>
                    An error was encountered trying to log you in, with Google. Please,
                    try again.
                </p>,
                'danger',
            );
        }
    };

    const handleErrorResponseFromGoogle = response => {
        console.warn(`Google login failure: ${JSON.stringify(response)}`);
        // User closed the popup, do nothing
        if (response?.error === 'popup_closed_by_user') {
            return;
        }

        // Google initialization failed, usually because of wrong clientId/disabled domain
        if (response?.error === 'idpiframe_initialization_failed') {
            setDisabledProviders([...disabledProviders, 'google']);
        } else {
            notify(
                'Error',
                <p>
                    An error was encountered trying to log you in, with Google. Please,
                    try again.
                </p>,
                'danger',
            );
        }
    };

    return (
        <SignUpForRoomLayout title="Create Your Account">
            <Box sx={{ mb: 4, textAlign: 'center' }}>
                <RoundedButton
                    data-testid="sign-up-button-sign-up"
                    className="fullWidth"
                    onClick={() => goToPage(appRoute.signUpForRoomEmail())}
                    sx={{ mb: 2 }}
                >
                    Sign Up with Email
                </RoundedButton>

                <GoogleLogin
                    clientId={REACT_APP_GOOGLE_CLIENTID}
                    render={renderProps => (
                        <RoundedButton
                            onClick={() => {
                                resetSocialSignIn();
                                renderProps.onClick();
                            }}
                            disabled={disabledProviders.includes('google')}
                            className="googleLogin fullWidth"
                            sx={{ mb: 2 }}
                        >
                            Sign up with Google
                        </RoundedButton>
                    )}
                    onSuccess={handleSuccessResponseFromGoogle}
                    onFailure={handleErrorResponseFromGoogle}
                    cookiePolicy={'single_host_origin'}
                />

                <FacebookLogin
                    appId={REACT_APP_FACEBOOK_APP_ID}
                    callback={responseFacebook}
                    fields="name,email"
                    render={renderProps => (
                        <RoundedButton
                            data-testid="log-in-button-log-in-with-facebook"
                            onClick={e => {
                                resetSocialSignIn();
                                renderProps.onClick(e);
                            }}
                            className="facebookLogin fullWidth"
                            loading={isSigningUp}
                            disabled={disabledProviders.includes('facebook')}
                            sx={{ mb: 2 }}
                        >
                            Sign up with Facebook
                        </RoundedButton>
                    )}
                />
                <p>
                    Please note, we've been experiecing issues with our Facebook login
                    option.
                    <br />
                    If you encounter any problems logging in with Facebook, please reach
                    out to our GS team for assistance with accessing your account.
                    <br />
                    They’ll be happy to help! Our team is available via LiveChat, email at{' '}
                    <br />
                    <a href="mailto:guestservices@destify.com">
                        GuestServices@destify.com
                    </a>
                    <br /> or by calling <br />
                    <a href="tel:8449483911">(844) 948-3911</a>
                    <br />
                    We sincerely apologize for any inconvenience.
                </p>
            </Box>

            <ConfirmSignUpModal
                open={socialModalOpen}
                defaultValues={socialData}
                onClosed={resetSocialSignIn}
            />
        </SignUpForRoomLayout>
    );
}
